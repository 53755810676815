
import { defineComponent, ref, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import SearchTable from '@/components/table/SearchTable.vue';
import TableModel from '@/components/composite/TableModel.vue';

/**
 * 采购订单
 */
export default defineComponent({
  name: 'ProductOrder',
  components: { TableModel, SearchTable },
  setup() {
    const { https, mapper, message } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'code',
              label: '订单号'
            },
            {
              field: 'uid',
              label: '下单人',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'status',
              label: '订单状态',
              type: 'select',
              options: mapper?.mall.productOrderStatus
            }
          ]
        },
        remote: {
          action: https?.mall.pageProductOrder
        }
      },
      cols: [
        {
          field: 'col-code',
          label: '订单信息',
          group: [
            {
              field: 'code',
              label: '订单号',
              ability: 'copy'
            },
            {
              field: 'statusDesc',
              label: '订单状态',
              options: mapper?.mall.productOrderStatus
            },
          ]
        },
        {
          field: 'col-pay',
          label: '支付信息',
          group: [
            {
              field: 'payNo',
              label: '支付单号',
              ability: 'copy'
            },
            {
              field: 'payType',
              label: '支付方式',
              options: mapper?.mall.payType
            }
          ]
        },
        {
          field: 'col-amount',
          label: '金额',
          width: 200,
          group: [
            {
              field: 'price',
              label: '价格'
            },
            {
              field: 'postage',
              label: '邮费'
            }
          ]
        },
        {
          field: 'col-receiver',
          label: '下单人',
          width: 200,
          group: [
            {
              field: 'alias',
              label: '用户名'
            },
            {
              field: 'phone',
              label: '手机号'
            },
          ]
        },
        // {
        //   field: 'backerNo',
        //   label: '品牌号',
        //   width: 120
        // },
        {
          field: 'remark',
          label: '备注',
          width: 160
        },
        {
          field: 'col-time',
          label: '时间',
          width: 220,
          group: [
            {
              field: 'createTime',
              label: '创建时间'
            },
            {
              field: 'updateTime',
              label: '更新时间'
            }
          ]
        },
      ],
      commands: [] as any[]
    };

    const detailRef = ref();
    const detailOpts = reactive({
      title: '采购详情',
      visible: false,
      content: {
        cols: [
          {
            field: 'productId',
            label: '商品ID'
          },
          {
            field: 'price',
            label: '价格'
          },
          {
            field: 'orderNum',
            label: '下单数量'
          },
          {
            field: 'actualNum',
            label: '实际数量'
          },
          {
            field: 'backerNo',
            label: '品牌',
            options: mapper?.device.backerNo
          }
        ]
      }
    });

    const showDetail = (data: DataItem) => {
      https?.mall.listProductOrderDetail({ no: data.code }).then(res => {
        if (res.respType === 'OK') {
          detailOpts.visible = true;
          detailRef.value.init(res.respData);
        } else {
          message?.warn(res.respMesg);
        }
      });
    };

    tableOpts.commands.push({ label: '详情', action: showDetail });
    return { tableOpts, detailRef, detailOpts };
  }
});
