
import { defineComponent, PropType, ref, reactive, nextTick } from 'vue';
import { ModalOptions } from '@/types/container.config';
import { SimpleTableOptions } from '@/types/table.config';
import { SimpleTableExposeType } from '@/types/types.config';

import ModalWidget from '../widget/ModalWidget.vue';
import SimpleTable from '../table/SimpleTable.vue';

/**
 * 简单的表格弹出框，
 */
export default defineComponent({
  name: 'TableModel',
  components: { SimpleTable, ModalWidget },
  props: {
    opts: {
      type: Object as PropType<ModalOptions<SimpleTableOptions>>,
      default: () => ({})
    }
  },
  setup(props) {
    const modalOpts = reactive(props.opts);
    const tableOpts = reactive(props.opts.content);
    const tableRef = ref<SimpleTableExposeType>();

    modalOpts.footer = false;
    tableOpts.bordered = false;

    const init = (data: any[]) => {
      nextTick(() => {
        tableRef.value?.expose.setData(data);
      });
    };

    return { modalOpts, tableOpts, tableRef, init };
  }
});
