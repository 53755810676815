import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_table = _resolveComponent("search-table")!
  const _component_table_model = _resolveComponent("table-model")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_table, { opts: _ctx.tableOpts }, null, 8, ["opts"]),
    _createVNode(_component_table_model, {
      ref: "detailRef",
      opts: _ctx.detailOpts
    }, null, 8, ["opts"])
  ]))
}